export default {
  themes: {
    light: {
      primary: '#757575', // rgb(36,105,189)
      secondary: '#E65100',
      processing: '#ffff99', // rgb(255 ,255, 153)
      error: '#91311d',
      background: '#F5F5F5',
      unvisitedLink: '#0000EE',
      visitedLink: '#551A8B',
      okButton: '#757575',
      tabsBackground: '#F44336',
      groupList: '#e0e0e0',
      warnReceipt: '#fcf005',
      errorReceipt: '#e60909',
      warnAfterReceipt: '#fcae05',
      loginButton: '#2597c5',
      fiscButton: '#e60909'
    },
    dark: {
      primary: '#F5F5F5',
      okButton: '#757575',
      tabsBackground: '#F44336',
      groupList: '#757575',
      warnReceipt: '#fcf005',
      errorReceipt: '#e60909',
      warnAfterReceipt: '#fcae05',
      loginButton: '#5d58a6'
    }
  }
}
