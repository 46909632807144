import hrVuetify from 'vuetify/es5/locale/hr'

const messages = {
  // These are the default vuetify translations
  ...hrVuetify,
  // Here write your custom transalations or override the default vuetify ones!! example:
  // dataTable: {
  //     itemsPerPageText: 'Redaka po mojoj stranici'
  // },
  language: 'Jezik',
  currency: 'Valuta',
  all: 'Sve',
  actions: 'Akcije',
  more: 'Više',
  save: 'Spremi',
  item: 'Artikl | Artikli',
  receipt: 'Račun | Računi',
  offer: 'Ponuda | Ponude',
  order: 'Narudžba',
  yes: 'Da',
  no: 'Ne',
  active: 'Aktivan',
  continue: 'Nastavi',
  taxName: 'PDV',
  reset: 'Resetiraj',
  confirm: 'Potvrdi',
  languages: {
    en: 'Engleski',
    hr: 'Hrvatski'
  },
  themes: {
    dark: 'Tamna',
    light: 'Svjetla'
  },
  appLanguageTxt: 'Jezik aplikacije',
  theme: 'Tema',
  price: 'Cijena',
  writeInButtonLabel: 'Slobodan unos artikla ili usluge',
  registerSuccessMsg: 'Uspješno ste registrirali novi račun. Molim potvrdite svoj email!',

  passwordReset: {
    infoMessage: 'Unesite vašu e-mail adresu da bi ste dobili link za promjenu zaporke.',
    resetBtn: 'Pošalji e-mail'
  },

  formDetails: {
    firstName: 'Ime',
    lastName: 'Prezime',
    password: 'Lozinka',
    confirmPassword: 'Potvrdite lozinku',
    termsAgree: 'Slažem se s uvjetima korištenja',
    terms: 'Uvjeti korištenja',
    name: 'Naziv',
    itemName: 'Naziv artikla ili usluge',
    address: 'Adresa',
    city: 'Grad',
    zipCode: 'Poštanski broj',
    country: 'Država',
    oib: 'Oib',
    requiredField: 'Obavezno polje',
    numGreater: 'Value has to be greater than {target}',
    passwordMatchRule: 'Lozinke moraju biti iste',
    oibValid: 'Unesite valjan OIB',
    oibDuplicate: 'OIB već unesen, molim unesite jedinstven OIB.',
    emailValid: 'Unesite valjanu e-mail adresu',
    numberValid: 'Unesite valjan broj',
    usernameValid: 'Unesite valjano korisničko ime',
    logIn: 'Prijava',
    register: 'Registriraj se',
    logOut: 'Odjavi se',
    registerText: 'Registracija',
    forgotPassword: 'Zaboravili ste lozinku?',
    phone: 'Telefon',
    mobile: 'Mobitel',
    additionalInfo: 'Dodatne informacije',
    associateCode: 'Šifra klijenta',
    leaveFormTitle: 'Da li ste sigurni da želite prekinuti akciju?',
    leaveFormText: 'Neke promjene možda neće biti spremljenje.',
    leave: 'Da',
    stay: 'Odustani',
    downloadReport: 'Preuzmi izvještaj',
    associateType: 'Tip klijenta',
    associateBuyer: 'Kupac',
    associateSupplier: 'Dobavljač',
    firstLast: 'Ime i prezime',
    issueReceipt: 'Račun/Ponuda',
    newArticle: 'aaa',
    unit: 'Jedinica',
    baseAmount: 'Osnovica',
    writeInTitle: 'Unos artikla ili usluge',
    supplierType: 'Tip dobavljača',
    supplierCode: 'Šifra dobavljača',
    username: 'Korisničko ime',
    saveAssociate: 'Spremi klijenta',
    changeReceiptType: 'Promijeni oznaku računa'
  },

  companiesTabs: 'Moje firme',
  companies: {
    titleText: 'Moje firme',
    addBtn: 'Dodaj firmu',
    types: {
      doo: 'd.o.o',
      jdoo: 'j.d.o.o',
      crafts: 'Obrt',
      pcrafts: 'Paušalni obrt'
    },
    taxType: 'Tip poreza',
    taxTypes: {
      profit: 'Porez na dobit',
      income: 'Porez na dohodak',
      none: 'Bez poreza'
    },
    warehouseType: 'Vrsta skladišta',
    warehouseTypes: {
      basic: 'Obično',
      module: 'Modul'
    },
    defaultIban: 'Glavni IBAN',
    notePOS: 'Napomena POS',
    noteA4: 'Napomena A4',
    receiptLogo: 'Logo računa',
    digitalSignatureImage: 'Slika digitalnog potpisa',
    imageDimensionExceeding: 'Odabrana slika je široka {width} i visoka {height} pixela. Najveća dozvoljena širina je {maxWidth} piksela a visina {maxHeight} piksela.',
    csv: 'Upload CSV-a'
  },

  users: {
    titleText: 'Korisnici',
    addUser: 'Dodaj korisnika',
    searchLabelText: 'Pretraga po korisničkom imenu'
  },

  add: 'Dodaj',

  reports: {
    titleText: 'Izvještaji',
    addUser: 'Generiraj izvještaj'
    // searchLabelText: 'Pretraga po korisničkom imenu'
  },
  uploadItems: {
    titleText: 'Uvoz artikala'
  },
  instructions: {
    titleText: 'Upute'
  },
  warehouse: {
    titleText: 'Skladište',
    titleText2: 'Skladište ',
    addItemsText: 'Dodaj artikl na skladište'
  },
  discount: {
    titleText: 'Popusti',
    rate: 'Postotni popust',
    amount: 'Apsolutni popust',
    manual: 'Ručni upis'
  },
  restaurant: {
    menu: 'Meni'
  },
  printerConfig: {
    title: 'Konfiguracija printera',
    installedPrinter: 'Instaliran Printer',
    installedPrinters: 'Instalirani Printeri',
    networkPrinter: 'Mrežni Printer',
    networkPrinters: 'Mrežni Printeri',
    btPrinter: 'Bluetooth Printer',
    btPrinters: 'Bluetooth Printeri',
    addPrinter: 'Dodaj printer',
    editPrinter: 'Uredi printer',
    selectPrinter: 'Odaberi printer',
    cancel: 'Odustani',
    printerName: 'Naziv printera',
    networkPrinterName: 'Naziv printera na mreži',
    ipAddress: 'Mrežna adresa printera',
    font: 'Font',
    wSize: 'Širina slova',
    hSize: 'Visina slova',
    size: 'Veličina slova',
    width: 'Širina (mm)',
    lineLength: 'Broj znakova u redu',
    port: 'Mrežni port'
  },
  posConfig: {
    title: 'Konfiguracija POS-a'
  },
  cardConfig: {
    title: 'Konfiguracija kartica'
  },
  inventura: {
    title: 'Inventure',
    active: 'Aktivna inventura'
  },
  orgUnitConfig: {
    title: 'Konfiguracija ustrojstvenih jedinica'
  },
  communicationConfig: {
    title: 'Komunikacija'
  },
  companyTabs: {
    admin: 'Admin',
    companySettings: 'Isključivanje firme',
    companyAdd: 'Dodjeljivanje firme',
    myCompanis: 'Moje firme',
    pointsOfSale: 'Prodajna mjesta',
    lager: 'Lista artikala',
    items: 'Artikli',
    categories: 'Kategorije artikala',
    receipts: 'Računi i ponude',
    associates: 'Klijenti',
    suppliers: 'Dobavljači',
    users: 'Korisnici',
    reports: 'Izvještaji',
    posdReport: 'PO - SD obrazac',
    kprReport: 'Knjiga prometa',
    uploadItems: 'Uvoz artikala',
    instructions: 'Upute',
    norm: 'Lista normativa',
    groupageColection: 'Zbirni promet',
    discounts: 'Popusti'
  },
  company: {
    admin: 'Admin',
    companySettings: 'Isključivanje firme',
    companyAdd: 'Dodjeljivanje firme',
    addPointOfSaleBtn: 'Dodaj prodajno mjesto',
    addLagerItemBtn: 'Dodaj artikl',
    addPrinterBtn: 'Dodaj printer',
    addOrgUnitBtn: 'Dodaj ustrojstvenu jedinicu',
    addAssociate: 'Dodaj klijenta',
    addSupplier: 'Dodaj dobavljača',
    addSupplierIntegration: 'Dodaj način komunikacije',
    addIntegration: 'Dodaj integraciju',
    addCategory: 'Dodaj kategoriju',
    associate: 'Klijent',
    suppliers: 'Dobavljači',
    supplier: 'Dobavljač',
    editAssociate: 'Editiraj klijenta',
    editSupplier: 'Editiraj dobavljača',
    addSupplierItems: 'Dodaj artikle za dobavljača',
    supplierItems: 'Dobavljačevi artikli',
    addNormBtn: 'Dodaj normativ',
    addDiscountBtn: 'Dodaj popust'
  },
  pointsOfSaleTabs: {
    admin: 'Admin',
    companySettings: 'Isključivanje firme',
    companyAdd: 'Dodjeljivanje firme',
    myCompanis: 'Moje firme',
    pointsOfSale: 'Prodajna mjesta',
    registers: 'Blagajne',
    lager: 'Lista artikala',
    categories: 'Kategorije artikala',
    warehouse: 'Prodajno mjesto',
    priceList: 'Cjenik',
    receipts: 'Računi i ponude',
    associates: 'Klijenti',
    users: 'Korisnici',
    reports: 'Izvještaji',
    uploadItems: 'Uvoz artikala',
    posdReport: 'PO - SD obrazac',
    kprReport: 'Knjiga prometa',
    instructions: 'Upute',
    warehouseItems: 'Artikli',
    warehouseDocuments: 'Dokumenti',
    primka: 'Unos primke',
    warehouseDocumentsOverview: 'Pregled dokumenata',
    warehouseDocumentsList: 'Lista dokumenata',
    norm: 'Lista normativa',
    groupageColection: 'Zbirni promet',
    discounts: 'Popusti',
    restaurant: 'Restoran',
    menu: 'Meni',
    master: 'Master',
    inventory: 'Inventura'
  },
  pointOfSale: {
    addLagerItemBtn: 'Dodaj artikl',
    addWarehouseItemBtn: 'Dodaj artikl na prodajno mjesto',
    setWarehouseModule: 'Dodaj artikl na cjenik',
    addCategory: 'Dodaj kategoriju',
    addAssociateBtn: 'Dodaj klijenta',
    addRegisterBtn: 'Dodaj blagajnu',
    addPricelistItemBtn: 'Dodaj artikl',
    stock: 'Stanje',
    searchItemsLabel: 'Pretraži artikle',
    cashRegisterNoAddOptionText: 'Niste u mogućnosti dodati niti jednu blagajnu, probajte dodati certifikat na firmu te pokušajte ponovo.',
    issueReceipt: 'Račun/Ponuda',
    newArticle: 'aaa'
  },

  userDataTabs: {
    userData: 'Postavke',
    bill: 'Račun',
    closeBalance: 'Zaključak blagajne'
  },
  cashRegisterTabs: {
    admin: 'Admin',
    companySettings: 'Isključivanje firme',
    companyAdd: 'Dodjeljivanje firme',
    myCompanis: 'Moje firme',
    pointsOfSale: 'Prodajna mjesta',
    registers: 'Blagajne',
    lager: 'Lista artikala',
    warehouse: 'Prodajno mjesto',
    priceList: 'Cjenik',
    receipts: 'Računi i ponude',
    reports: 'Izvještaji',
    posdReport: 'PO - SD obrazac',
    kprReport: 'Knjiga prometa',
    instructions: 'Upute',
    norm: 'Lista normativa',
    closeBalanceTitles: 'Zaključci blagajne',
    groupageColection: 'Zbirni promet',
    logout: 'Odjavi se sa blagajne'
  },
  cashRegister: {
    registerTypes: {
      mp: 'Maloprodajna blagajna',
      vp: 'Veleprodajna blagajna',
      all: 'Sve'
    },
    registerLoginTypes: {
      default: 'Prijava korisnikom - klasično',
      selectUser: 'Prijava odabirom korisnika',
      pincode: 'Prijava unosom PIN-a korisnika'
    },
    afterReceiptBehaviours: {
      emptyBasket: 'Isprazni košaricu',
      showCashRegs: 'Prikaži blagajne',
      showAllReceipts: 'Prikaži sve račune'
    },
    noCategoryItems: 'Ne postoje artikli za kategoriju {category} ili nema artikala u odabranoj valuti. Probajte dodati nove artikle u cijenik ili dodati valutu za željeni artikl.',
    noCategories: 'Ne postoje niti jedna kategorija. Probajte dodati jednu.',
    lastReceiptNumber: 'Zadnji broj računa',
    lastOfferNumber: 'Zadnji broj ponude',
    optionsText: 'Opcije blagajne',
    closeBalanceTitle: 'Zaključak blagajne',
    createRegisterReport: 'Kreiraj izvještaj za blagajnu',
    closingBalanceSearch: 'Datum zaključka',
    user: 'Korisnik ',
    otherUserReserved: ' se ulogirao u blagajnu.',
    selectTemplate: 'Promijeni template blagajne',
    search: 'Pretraži artikle',
    searchDescription: 'Pretraži artikle po nazivu ili po šifri',
    receiptsOverview: 'Pregled računa',
    selectProduct: 'Odaberi proizvod',
    searchByReceiptNumber: 'Pretraga po broju računa'
  },
  lager: {
    itemName: 'Naziv artikla',
    itemTypes: {
      goods: 'Materijalna dobra',
      service: 'Servisi i usluge'
    }
  },
  priceList: {
    dateFromGreater: 'Vrijeme aktivacije veće od vremena trajanja artikla!',
    noPrice: 'Odaberite barem jednu cijenu za artikl'
  },
  receipts: {
    receiptNumber: 'Broj računa',
    offerNumber: 'Broj ponude',
    customer: 'Klijent',
    receiptPrice: 'Iznos računa',
    offerPrice: 'Iznos ponude',
    receiptIssueDate: 'Datum izdavanja',
    receiptDeliveryDate: 'Datum isporuke',
    receiptPaymentDate: 'Datum plaćanja',
    receiptPaymentDue: 'Datum valute',
    retailCustomer: 'Maloprodajni kupac',
    defaultNote: 'Račun po ponudi broj {offer}.',
    defaultVirman: 'Račun po virmanu broj {virman}.',
    receiptPaidStatus: 'Plaćeno',
    status: 'Status računa',
    PAID: 'Plaćeno',
    UNPAID: 'Neplaćeno',
    PROCESSING: 'Procesiranje',
    EMAIL_SENT: 'E-mail poslan',
    OFFER_SENT: 'E-mail poslan',
    WARNING_SENT: 'Opomena poslana',
    OFFER_CREATED: 'Ponuda kreirana',
    VIRMAN_CREATED: 'Virman kreiran',
    UNKNOWN_STATUS: 'Nepoznat status',
    OK: 'bsfdsf',
    DUPLICATE: 'fsdfdsfdsgfsdgsad',
    fiscalizing: 'Fiskaliziraj sve račune',
    REVERSED: 'Storniran'
  },
  tables: {
    tables: 'Stolovi',
    create: 'Kreiraj novi',
    close: 'Završi',
    select: 'Odaberi stol',
    selected: 'Odabrani stol',
    saveTo: 'Spremi na',
    saveTable: 'Spremi',
    clear: 'Obriši',
    new: 'Nova',
    save_title: 'Spremanje narudžbe',
    save: 'Želite li spremiti trenutnu narudžbu na stol',
    clearOrder: 'Da li želite obrisati narudžbu sa stola',
    delete: 'Želite li obrisati',
    unlockTitle: 'Otključavanje stola',
    unlockMessage: 'je zaključan. Jeste li sigurni da želite otključati stol?',
    unlockMessageOther: 'je zaključan kod drugog korisnika. Jeste li sigurni da želite otključati stol?',
    note: 'Napomena',
    newTable: 'Spremi na novi stol',
    existingTable: 'Prebaci na postojeći stol',
    splitTablesDisclaimer: 'Kliknite na znak ">" za prebacivanje jednog ili na znak ">>" za prebacivanje svih artikala koje želite naplatiti ili prebaciti na drugi stol.',
    splitTable: 'Podijeli stol',
    splitTableAll: 'Prebaci sve',
    transferTableTitle: 'Predaj stol',
    transferTableButton: 'Predaj stol',
    receiptNote: 'Napomena na računu',
    addReceiptNote: 'Dodaj napomenu',
    selectedItems: 'Artikli',
    noteMessage: 'U košarici postoji narudžba koja nije završena. Želite li nastaviti bez završavanja narudžbe?',
    continue: 'Nastavi',
    cancel: 'Odustani',
    cartRecpitulation: 'Rekapitulacija stola'
  },
  tooltips: {
    payWithCash: 'Plati račun gotovinom',
    payWithCards: 'Odaberi karticu za bezgotovinsko plaćanje',
    payR1: 'R1 račun, gotovinski, bezgotovinski ili virmanom',
    selectTable: 'Odaberi postojeći stol ili stvori novi',
    newOrder: 'Ukloni trenutni odabir i kreni sa novom narudżbom',
    clearTable: 'Isprazni odabrani stol. Ako je stol označen za brisanje, bit će obrisan iz baze',
    saveTable: 'Sadržaj košarice spremi na odabrani stol',

    searchItems: 'Pretraži artikle po nazivu',
    repReceipt: 'Označi račun kao reprezentaciju',
    receiptsDialog: 'Pretraži sve račune od posljednjeg zaključka blagajne',
    closeBalance: 'Zaključak blagajne',
    splitTable: 'Podijeli račun za posebno plaćanje odabranih artikala',

    wolt: 'Plaćanje putem Wolta',
    bolt: 'Plaćanje putem Bolta',
    glovo: 'Plaćanje putem Glova',

    enableOsk: 'Uključi/isključi ekransku tipkovnicu'
  },
  errors: {
    errorTitle: 'Greška',
    nullReceipt: 'Nema podataka o računu.',
    nullClosingBalance: 'Nema podataka o zaključku blagajne.',
    nullReportReceipts: 'Ne postoje računi u danom vremenu.',
    unknownRegister: 'Ne postoji kasa na kojoj je napravljen račun ili je u krivom statusu.',
    noCashRegisterData: 'Tehnički problem: Blagajna nedostupna.',
    noCorrespondingUser: 'Email ili lozinka nisu ispravni.',
    wrongPassword: 'Email ili lozinka nisu ispravni.',
    tooManyRequests: 'Pristup vašem računu trenutno je blokiran zbog previše pokušaja upisa lozinke. Možete odmah dobiti pristup ako promjenite vašu lozinku ili pokušajte se ulogirati kasnije.',
    simpleSequence: 'Lozinka nije ispravna: Ne smije sadržavati jednostavan brojčani slijed.',
    passwordContains: 'Lozinka nije ispravna: Ne smije sadržavati ime, prezime ili e-mail adresu.',
    shortPassword: 'Lozinka nije ispravna: Mora sadržavati najmanje 8 znakova.',
    isNotSeller: 'Nemate privilegiju prodavača na ovom prodajnom mjestu, te niste u mogućnosti izdavati račune.<br/>Da biste dodali privilegiju prodavača odaberite<br/> Postavke → Korisnici te za korisnika odaberite privilegiju “PRODAVAČ”.',
    notSellerButSuperuser: 'Niste prodavač, Dodaj privilegiju prodavača?',
    invalidDataUpload: 'Nije uspio uvoz artikala. Molimo pokušajte ponovno.',
    invalidCashRegReservation: 'Nije uspjelo zauzeće blagajne. Molimo pokušajte ponovno.',
    noLeadingSpaces: 'Nisu dozvoljeni razmaci na početku naziva.',
    passwordNumbers: 'Lozinka nije ispravna: Mora sadržavati slova i brojeve.',
    noChangesInEntities: 'Niste napravili izmjene.',
    duplicateCompany: 'Firma sa ovim OIB-om vec je registrirana u sustavu',
    duplicateItem: 'Ovaj artikl je već registriran u sustavu',
    itemCodeDuplicate: 'Šifra koju ste unijeli već postoji u sustavu.',
    accountDisabled: 'Administrator je deaktivirao vaš korisnički račun.'
  },
  messages: {
    resetLinkSent: 'Ukoliko je adresa registrairana u sustavu link za reset lozinke je poslan na vašu e-mail adresu.',
    multipleSignIn: 'Ulogirali ste se na drugom uređaju, ova sesija se prekida.'
  },
  addSellerRole: 'Dodaj privilegiju prodavača?',
  addRole: 'Dodaj privilegiju',
  process: 'Obradi',
  cancel: 'Odustani',
  backward: 'Nazad',
  createRegisterReportBtn: 'Kreiraj izvještaj za blagajnu',
  finishPayment: 'Završi',
  paymentMethodText: 'Način plaćanja',
  issueYear: 'Godina izdavanja',
  issueDate: 'Datum izdavanja računa',
  deliveryDate: 'Datum isporuke',
  paymentDueDate: 'Datum dospijeća računa',
  offerDueDate: 'Datum važenja ponude',
  fiscalizeText: 'Fiskaliziraj',
  receiptTypeText: 'Tip računa',
  amountReceivedText: 'Primljeni iznos',
  name: 'Naziv',
  quantity: 'Količina',
  receiptTitle: 'Plaćanje',
  referenceNumber: 'Poziv na broj',
  receiptNumber: 'Broj računa',
  offerNumber: 'Broj ponude',
  change: 'Ostatak',
  total: 'Ukupno',
  totalWithDiscount: 'Ukupno sa popustom',
  note: 'Napomena',
  journalNum: 'Broj temeljnice',
  noItemsSelected: 'Nema odabranih artikala',
  accountDetailsTitle: 'Detalji računa',
  receiptTypes: {
    noCustomerData: 'Bez podataka o kupcu',
    withCustomerData: 'Sa podacima o kupcu',
    offer: 'Ponuda'
  },
  paymentMethods: {
    cash: 'Gotovinski',
    card: 'Kartično',
    transaction: 'Transakcijski',
    virman: 'Virman',
    representation: 'Reprezentacija',
    cashMethod: 'Gotovinsko',
    cardMethod: 'Bezgotovinsko',
    offer: 'Ponuda'
  },
  receiptLanguages: {
    en: 'Engleski',
    hr: 'Hrvatski'
  },
  notifications: {
    title: 'Obavijesti'
  },
  companySettings: {
    title: 'Isključivanje firme'
  },
  companyAdd: {
    title: 'Dodavanje firme na support'
  },
  contractNumText: 'Broj ugovora',
  filterLabel: 'Pretraga',
  filterPlaceholderLabel: 'Počnite tipkati za pretragu...',
  searchLabel: 'Pritisnite enter za pretragu',
  filterOib: 'Klijent (OIB)',
  filterReceiptNumber: 'Broj računa',
  showAdditionalFilters: 'Prikaži dodatne filtere',
  hideAdditionalFilters: 'Sakrij dodatne filtere',
  orders: {
    titleText: 'Narudžbe',
    createOrder: 'Kreiraj narudžbu',
    showOrders: 'Pregledaj narudžbe'
  }

}

export default {
  $vuetify: messages
}
