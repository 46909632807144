import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import themes from './theme'
import i18n from '@/plugins/i18n'
Vue.use(Vuetify)
export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    ...themes
  },
  lang: {
    current: i18n.locale,
    t: (key, ...params) => i18n.t(key, params)
  }
})
